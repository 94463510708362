<template>
    <client-page>

        <sub-visual-intro />

        <section class="section">
            <v-container>

                <tab-intro />

                <iframe class="d-block w-100 mb-20 mb-lg-40" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3160.9540632207763!2d127.03990631558923!3d37.60324012978254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbb7e79c5198b%3A0x889f6e2ee3aeb6ce!2z7ISc7Jq47Yq567OE7IucIOyEseu2geq1rCDtmZTrnpHroZwxMeq4uCAyNg!5e0!3m2!1sko!2skr!4v1674731063817!5m2!1sko!2skr" height="680" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                <v-divider />

                <div class="page-text my-12 my-lg-24">

                    <div class="d-flex mb-4 mb-lg-10">
                        <p class="primary--text font-weight-medium min-w-80px mr-lg-10">
                            주소
                        </p>
                        <p>서울시 성북구 화랑로 11길 26 215호</p>
                    </div>
                    <div class="d-flex mb-4 mb-lg-10">
                        <p class="primary--text font-weight-medium min-w-80px mr-lg-10">
                            전화
                        </p>
                        <a href="tel: 02-2057-2296">02-2057-2296</a>
                    </div>
                    <div class="d-flex">
                        <p class="primary--text font-weight-medium min-w-80px mr-lg-10">
                            주변 지하철
                        </p>
                        <p>월곡역, 상월곡역</p>
                    </div>

                </div>

                <v-divider />

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualIntro from "@/components/client/sub/sub-visual-intro.vue";
import TabIntro from "@/components/client/sub/tab-intro.vue";
export default {
    components: {
        ClientPage,
        SubVisualIntro,
        TabIntro,
    },
};
</script>

<style lang="scss" scoped>
</style>