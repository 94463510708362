var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-intro'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('tab-intro'), _c('iframe', {
    staticClass: "d-block w-100 mb-20 mb-lg-40",
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3160.9540632207763!2d127.03990631558923!3d37.60324012978254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbb7e79c5198b%3A0x889f6e2ee3aeb6ce!2z7ISc7Jq47Yq567OE7IucIOyEseu2geq1rCDtmZTrnpHroZwxMeq4uCAyNg!5e0!3m2!1sko!2skr!4v1674731063817!5m2!1sko!2skr",
      "height": "680",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  }), _c('v-divider'), _c('div', {
    staticClass: "page-text my-12 my-lg-24"
  }, [_c('div', {
    staticClass: "d-flex mb-4 mb-lg-10"
  }, [_c('p', {
    staticClass: "primary--text font-weight-medium min-w-80px mr-lg-10"
  }, [_vm._v(" 주소 ")]), _c('p', [_vm._v("서울시 성북구 화랑로 11길 26 215호")])]), _c('div', {
    staticClass: "d-flex mb-4 mb-lg-10"
  }, [_c('p', {
    staticClass: "primary--text font-weight-medium min-w-80px mr-lg-10"
  }, [_vm._v(" 전화 ")]), _c('a', {
    attrs: {
      "href": "tel: 02-2057-2296"
    }
  }, [_vm._v("02-2057-2296")])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "primary--text font-weight-medium min-w-80px mr-lg-10"
  }, [_vm._v(" 주변 지하철 ")]), _c('p', [_vm._v("월곡역, 상월곡역")])])]), _c('v-divider')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }